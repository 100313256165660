//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import axios from "axios";
import api from "../api.js";
export default {
  data: () => ({
    form: false,
    bDialogTasques: false,
    itemOne: {
      wait: "N",
      waitingReason: "",
      state: "",
    },
    orderState: "",
    orderStateColor: "",
    rules: {
      length: (len) => (v) =>
        (v || "").length >= len || `Invalid character length, required ${len}`,
      required: (v) => !!v || "This field is required",
    },
  }),
  created() { },
  mounted() {
    this.getItem();
  },
  methods: {
    goBack() {
      this.$router.push("/workcalendaredit");
    },
    goMenu() {
      this.$router.push("/mainmenu");
    },
    goWorkPerformed() {
      this.updateDone(true);
      this.$router.push({
        name: "WorkPerformed",
        params: { color: "green lighten-2" },
      });
    },
    getStyle(state) {
      if (state != "A") {
        return "color: green";
      } else {
        return "color: black";
      }
    },
    getItem() {
      axios
        .post(api.URL() + "/api/v1/gordresfabricacio00_getstate", {
          token: sessionStorage.getItem("Token"),
          docCompanyId: parseInt(sessionStorage.getItem("sessDocCompanyId")),
          docYear: parseInt(sessionStorage.getItem("sessDocYear")),
          docSerialId: parseInt(sessionStorage.getItem("sessDocSerialId")),
          docId: parseInt(sessionStorage.getItem("sessDocId")),
        })
        .then((response) => {
          if (response.error == null) {
            this.omplirValors(response.data);
          } else {
            this.msgAlert(response.error, true);
          }
        })
        .catch(function (error) {
          this.msgAlert(error.response, true);
        });
    },
    omplirValors(rdata) {
      this.itemOne = rdata;
      this.orderState = "TREBALLANT";
      this.orderStateColor = "blue lighten-2";
      if (this.itemOne.state == "W") {
        this.orderState = "PAUSADA PER L'OPERARI";
        this.orderStateColor = "red lighten-2";
      }
      if (this.itemOne.state == "C") {
        this.orderState = "COMPLETADA PER L'OPERARI";
        this.orderStateColor = "green lighten-2";
      }
    },
    updateDone(newState) {
      var self = this;
      self.bDialogTasques = false;
      axios
        .post(api.URL() + "/api/v1/gordresfabricacio00_updateoperatordone", {
          token: sessionStorage.getItem("Token"),
          docCompanyId: parseInt(sessionStorage.getItem("sessDocCompanyId")),
          docYear: parseInt(sessionStorage.getItem("sessDocYear")),
          docSerialId: parseInt(sessionStorage.getItem("sessDocSerialId")),
          docId: parseInt(sessionStorage.getItem("sessDocId")),
          userId: parseInt(sessionStorage.getItem("UserId")),
          done: newState,
        })
        .then((response) => {
          if (response.error != null) {
            self.msgAlert(response.error, true);
          }
          self.getItem();
        })
        .catch(function (error) {
          self.msgAlert(error.response, true);
        });
    },
    updateWait(newState, message) {
      axios
        .post(api.URL() + "/api/v1/gordresfabricacio00_updateoperatorwait", {
          token: sessionStorage.getItem("Token"),
          docCompanyId: parseInt(sessionStorage.getItem("sessDocCompanyId")),
          docYear: parseInt(sessionStorage.getItem("sessDocYear")),
          docSerialId: parseInt(sessionStorage.getItem("sessDocSerialId")),
          docId: parseInt(sessionStorage.getItem("sessDocId")),
          userId: parseInt(sessionStorage.getItem("UserId")),
          wait: newState,
          waitReason: message,
        })
        .then((response) => {
          if (response.error != null) {
            this.msgAlert(response.error, true);
          }
          this.getItem();
        })
        .catch(function (error) {
          this.msgAlert(error.response, true);
        });
    },
    msgAlert(missatge, close) {
      var onClose = () => {
        if (close && close == true) {
          this.$router.push("/");
        }
      };
      this.$alert.show({ message: missatge, onClose: onClose });
    },
  },
};
